import { useCallback, useState } from "react";
import secureFetch from "./secureFetch";
import { useAuth } from "./useAuth";
import { API_URL } from "../apiConfig";
import { OrderPdfTemplate } from "../components/OrderPDFTemplate";
import { pdf } from "@react-pdf/renderer";

const baseUrl = `${API_URL}/order`;

export function useOrder() {
  const { refreshToken } = useAuth();
  const [order, setOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Create a new order
  const createOrder = async ({ items, shippingDetails }) => {
    setLoading(true);
    setError(null);
    try {
      const response = await secureFetch(
        `${baseUrl}`,
        {
          method: "POST",
          data: { items, shippingDetails }, // Pass items in the body
        },
        refreshToken
      );
      setOrder(response.data.order);
      setLoading(false);
      return response.data.order;
    } catch (err) {
      console.error(err);
      setError(err.message || "Failed to create order");
      setLoading(false);
    }
  };

  // Fetch all orders for the current user
  const getUserOrders = useCallback(
    async (userId) => {
      setLoading(true);
      setError(null);
      try {
        const response = await secureFetch(`${baseUrl}/user/${userId}`, {}, refreshToken);
        setOrders(response.data.orders);
        setLoading(false);
        return response.data.orders;
      } catch (err) {
        console.error(err);
        setError(err.message || "Failed to fetch orders");
        setLoading(false);
        return [];
      }
    },
    [refreshToken]
  );

  const getOrderById = useCallback(
    async (orderId) => {
      setLoading(true);
      setError(null);
      try {
        const response = await secureFetch(`${baseUrl}/${orderId}`, {}, refreshToken);
        return response.data.order;
      } catch (err) {
        console.error(err);
        setError(err.message || "Failed to fetch order");
      } finally {
        setLoading(false);
      }
    },
    [refreshToken]
  );

  // Update order status (for admin)
  const updateOrderStatus = async (orderId, status) => {
    setLoading(true);
    setError(null);
    try {
      const response = await secureFetch(
        `${baseUrl}/${orderId}`,
        {
          method: "PATCH",
          data: { status },
        },
        refreshToken
      );
      setOrder(response.data.order);
      setLoading(false);
      return response.data.order;
    } catch (err) {
      console.error(err);
      setError(err.message || "Failed to update order status");
      setLoading(false);
    }
  };

  // Fetch all orders (admin access)
  const getAllOrders = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await secureFetch(`${baseUrl}`, {}, refreshToken);
      setOrders(response.data.orders);
      // console.log(response.data.orders);
      setLoading(false);
      return response.data.orders;
    } catch (err) {
      console.error(err);
      setError(err.message || "Failed to fetch all orders");
      setLoading(false);
      return [];
    }
  }, [refreshToken]);

  const deleteOrder = async (orderId) => {
    setLoading(true);
    setError(null);
    try {
      const response = await secureFetch(
        `${baseUrl}/${orderId}`,
        {
          method: "DELETE",
        },
        refreshToken
      );
      // Optionally, remove the order from the local state
      setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderId));
      setLoading(false);
      return response.data;
    } catch (err) {
      console.error(err);
      setError(err.message || "Failed to delete order");
      setLoading(false);
    }
  };
  

  const downloadOrderPDF = async (orderId, specificOrder = null) => {
    try {
      // Use the provided order if available, otherwise fetch the order
      let order = specificOrder || orders.find((order) => order.id === orderId);
  
      // If the order isn't in the orders array or passed as an argument, fetch it
      if (!order) {
        order = await getOrderById(orderId);
      }
  
      // console.log(orderId, order);
  
      if (!order) return;
  
      // Generate the PDF document
      console.log(order)
      const doc = <OrderPdfTemplate order={order} />;
  
      // Create the PDF blob
      const asPdf = pdf([]);
      asPdf.updateContainer(doc); // Update the PDF container with the document content
      const blob = await asPdf.toBlob(); // Convert it to a blob
  
      // Trigger the download using a temporary anchor tag
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Order_${order.id}.pdf`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(url); // Clean up the blob URL
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  

  return {
    createOrder,
    getUserOrders,
    getOrderById,
    updateOrderStatus,
    getAllOrders,
    downloadOrderPDF,
    deleteOrder,
    order,
    orders,
    loading,
    error,
  };
}
